<script setup lang="ts">
import { RouterView } from "vue-router";
import { useCustomizerStore } from "@/stores/customizer";
import LcFullHorizontalHeader from "@/components/lc/Full/horizontal-header/index.vue";
import LcFullHorizontalSidebar from "@/components/lc/Full/horizontal-sidebar/index.vue";
import SToast from "@/components/s-components/SToast.vue";
const router = useRouter();
const customizer = useCustomizerStore();
const title = ref("CONNECT");
useHead({
  meta: [{ content: title }],
  titleTemplate: (titleChunk: any) => {
    return titleChunk ? `${titleChunk} ` : "CONNECT";
  },
  script: [{ src: "/js/pao-seatmap-ui.js", defer: true }],
});
</script>

<template>
  <v-locale-provider>
    <v-app
      :theme="customizer.actTheme"
      :class="[
        customizer.actTheme,
        customizer.mini_sidebar ? 'mini-sidebar' : '',
        customizer.setHorizontalLayout ? 'horizontalLayout' : 'verticalLayout',
        customizer.setBorderCard ? 'cardBordered' : '',
      ]"
    >
      <LcFullHorizontalHeader v-if="customizer.setHorizontalLayout" />
      <LcFullHorizontalSidebar v-if="customizer.setHorizontalLayout" />
      <v-main>
        <v-container
          fluid
          :class="
            router.currentRoute.value.path !== '/help' &&
            router.currentRoute.value.path !== '/help/partners'
              ? 'page-wrapper pb-sm-15 pb-10'
              : 'pa-0 pt-1'
          "
        >
          <div
            :class="
              customizer.boxed &&
              router.currentRoute.value.path !== '/help' &&
              router.currentRoute.value.path !== '/help/partners'
                ? 'maxWidth'
                : ''
            "
          >
            <RouterView />
          </div>
          <s-toast />
        </v-container>
      </v-main>
    </v-app>
  </v-locale-provider>
</template>
